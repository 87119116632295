
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import getCurrentQuery from '@/utils/querystrings'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
@Component({
  name: 'sinalogin',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class sinaloginPage extends Vue {
  public async created () {
    const data:any = {
      auth_code: getCurrentQuery('code') || '',
      state: getCurrentQuery('state') || ''
    }
    try {
      const res = await httpHelper.getAuthLogin(data, 'authorize/weibo')
      this.goTo(res, 9, 8)
    } catch (err) {
      this.goTo(err, 9, 8)
      this.$toasted.show(err.msg)
      setTimeout(() => {
        location.replace('/sms')
      }, 3000)
    }
  }
}
